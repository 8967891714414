import "./App.css";
import Hls from "hls.js";
import { useEffect, useRef } from "react";

function App() {
  const player = useRef(null);
  useEffect(() => {
    const video = player.current;
    const hls = new Hls();
    const url = "https://hls.bantersquad.tv/test.m3u8";

    hls.loadSource(url);
    hls.attachMedia(video);
    // hls.on(Hls.Events.MANIFEST_PARSED, function () {
    //   video.play();
    // });
  }, [player]);
  return (
    <div className="App">
      <header className="App-header">
        <h1>Film Lounge</h1>
        <video controls ref={player} autoPlay={true} muted={true} />
      </header>
    </div>
  );
}

export default App;
